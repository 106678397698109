@font-face {
    font-family: Kanit;
    font-weight: 600;
    src: url("./Kanit-SemiBold.ttf");
}

@font-face {
    font-family: Kanit;
    font-weight: 500;
    src: url("./Kanit-Regular.ttf");
}

@font-face {
    font-family: Kanit;
    font-weight: 700;
    src: url("./Kanit-Bold.ttf");
}
